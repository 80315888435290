$(document).ready(function(){
    $('#phoneNumber').inputmask({"mask": "999 999 9999"});
    $('#mobile').inputmask({"mask": "999 999 9999"});
    $('#advocateMobile').inputmask({"mask": "999 999 9999"});
    $('#altPhoneNumber').inputmask({"mask": "999 999 9999"});
    $('#physPhone').inputmask({"mask": "999 999 9999"});
    $('#physFax').inputmask({"mask": "999 999 9999"});
    $('#patientPhone').inputmask({"mask": "999 999 9999"});
    $('#patientFax').inputmask({"mask": "999 999 9999"});
    $('#postalCode').inputmask({"mask": "A9A 9A9", "jitMasking": true});
    $('#altPostalCode').inputmask('A9A');
    $('#physPCode').inputmask('A9A 9A9');
    $('#patientPCode').inputmask('A9A 9A9');
    $('#dateOfBirth').inputmask( {"mask": "9999-99-99", "placeholder": "yyyy-mm-dd"});
    $('#clientId').inputmask( {"mask":'AAAAAAAAAA999AA', "jitMasking": true} );
    $('#cvv').inputmask('999');
    $('#din').inputmask('99999999');

    $('#advocatePhoneNumber').inputmask({"mask": "999 999 9999"});
    $('#advocatePostalCode').inputmask('A9A 9A9');

    $('#physProv').inputmask('AA');
    $('#patientProv').inputmask('AA');

    $('[data-toggle="tooltip"]').tooltip();
    $('#subjectNumber').inputmask('999999');

    $('#month').inputmask('99');

    $('#month').inputmask({ 
        mask: '99',
        onBeforeWrite: function (event, buffer) {
            if (event.type !== 'blur') {
                return null;
            }
            let currentValue = buffer.join('');
            return {
                refreshFromBuffer: true,
                buffer: (('0' + parseInt(currentValue)).slice(-2)).split('')
            };
        }
    });



    $('#day').inputmask({ 
        mask: '99',
        onBeforeWrite: function (event, buffer) {
            if (event.type !== 'blur') {
                return null;
            }
            let currentValue = buffer.join('');
            return {
                refreshFromBuffer: true,
                buffer: (('0' + parseInt(currentValue)).slice(-2)).split('')
            };
        }
    });

    $('#year').inputmask({ 
        mask: '9999',
        onBeforeWrite: function (event, buffer) {
            if (event.type !== 'blur') {
                return null;
            }
            let currentValue = buffer.join('');
            return {
                refreshFromBuffer: true,
                buffer: (('0000' + parseInt(currentValue)).slice(-4)).split('')
            };
        }
    });
    
});
