var $noneSelectedText;
var $numSelected;
var $language;

$(function() {
    $url = window.location.href;
    if($url.indexOf('/fr/') >= 0) {
        $noneSelectedText = 'Aucune sélection';
        $numSelected = ' sélections';
        $language = 'fr';
    } else {
        $noneSelectedText = 'None selected';
        $numSelected = ' selected';
        $language = 'en';
    }
});

$(function() {
    var current_year = new Date().getFullYear();
    $('.sti-birth-date-picker').datepicker({
        language: $language,
        pickTime: false,
        autoclose: true,
        startView: 'month',
        defaultViewDate: { year: current_year},
        format: "yyyy-mm-dd"
    });
});

$(function() {
  $('.sti-typeable-select').selectize();
});

$(function() {
    $('.sti-multiselect').multiselect({
        nonSelectedText: $noneSelectedText,
        nSelectedText: $numSelected,
        buttonContainer: '<div class="btn-group" />',
        buttonWidth: '100%',
        maxHeight: 400,
        numberDisplayed: 1
    });
});
