$(document).ready(function(){

    //Make sure the submit button in enabled ..
    $('form#cardFormId').find(":submit").prop("disabled", false);

    //Disable on submit event only
    $( "form#cardFormId" ).on('submit',function(e) {

        $("form#cardFormId input[type=submit]").attr("disabled", "disabled");
    });

    //Make sure the submit button in enabled ..
    $('form#registerFormId').find(":submit").prop("disabled", false);

    //Disable on submit event only
    $( "form#registerFormId" ).on('submit',function(e) {

        $("form#registerFormId input[type=submit]").attr("disabled", "disabled");
    });
});