/*********************************************************************/
$(document).ready(function(){
    $('#hamburger-menu').click(function() {
        $('#navbar-hamburger').toggleClass('hidden');
        $('#navbar-close').toggleClass('hidden');  
      });
    
    if ($('.alert').length > 0) {
         $('html, body').animate({
            scrollTop: parseInt($('.alert').offset().top - 226) // 226 is the height of the header
         }, 1000);
    }

    //Make sure the submit button in enabled ..
    $('form#contraveRegisterFormId').find(":submit").prop("disabled", false);

    //Disable on submit event only
    $("form#contraveRegisterFormId").on('submit', function (e) {
        $("form#contraveRegisterFormId input[type=submit]").attr("disabled", "disabled");
    });

    //Open SupportModal on click
    $("#supportModalLink").on('click', function (event) {
        event.preventDefault();
        $("#supportModal").modal({
            keyboard: false,
            show: true,
            backdrop: 'static',
        });
    });
});